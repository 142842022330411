import ReactGA4 from "react-ga4";
import { PRODUCTION_APP_URL } from "constants/routes";

ReactGA4.initialize("G-KXG1PCPVHT");

export const sendGAPageView = () => {
  let currentURL = window.location.href;

  if (currentURL.includes(PRODUCTION_APP_URL)) {
    ReactGA4.send("pageview");
  }
};
