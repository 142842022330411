import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ROUTES } from "constants/routes";
import InvalidPage from "components/InvalidPage";
import { sendGAPageView } from "utils/googleAnalytics";

function Main() {
  const location = useLocation();
  const [contentTopPos, setContentTopPos] = useState(0)

  useEffect(() => {
    const currentPathname = location.pathname;
    const isValidPath = ROUTES.some(
      (route) => route.path.trim() === currentPathname
    );

    if (isValidPath) {
      sendGAPageView();
    }
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('load', updateDimensions);
    updateDimensions();

    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('load', updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    const navbarElement = document.getElementById('mobile-navbar');
    if (navbarElement) {
      const navbarHeight = navbarElement.offsetHeight;

      // Adjust content's top value for non-desktop view
      const isDesktopView = window.matchMedia('(min-width: 1024px)').matches;
      const bannerTopValue = isDesktopView ? 0 : navbarHeight;
      setContentTopPos(bannerTopValue);
    }
  };


  return (
    <div className="relative" style={{paddingTop: contentTopPos || 0}}>
      <Routes>
        {ROUTES.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.Component />}
          />
        ))}
        <Route path="*" element={<InvalidPage />} />
      </Routes>
    </div>
  );
}

export default React.memo(Main);
